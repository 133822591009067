// Typography

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
  font-family: $headings-font-family;
  font-weight: 400;
  margin: 0 0 0.53em;
  color: $headings-color;
  display: inline-block;
  vertical-align: top;
  border-bottom: 1px solid $orange;
  text-transform: uppercase;

  @include media('<phone') {
    font-weight: 600;
  }
}

h1, .h1 {
  font-size: $h1-font-size;
  padding: 0 0 0.11em;
  font-weight: 600;

  @include media('<desktop') {
    font-size: 40px;
  }
  @include media('<tablet') {
    font-size: 29px;
  }
}

h2, .h2 {
  font-size: $h2-font-size;
  margin: 0 0 0.55em;
  padding: 0 0 0.25em;

  @include media('<desktop') {
    font-size: 29px;
  }
  @include media('<tablet') {
    font-size: 22px;
  }
  @include media('<phone') {
    font-size: 17px;
    line-height: 1.6;
    padding: 0 0 0.5em;
    margin: 0 0 1em;
  }
}

h3, .h3 {
  font-size: $h3-font-size;
  border: none;
  padding: 0;

  @include media('<desktop') {
    font-size: 18px;
    line-height: 1.8;
  }

  @include media('<tablet') {
    font-size: 14px;
  }

  @include media('<phone') {
    font-size: 10px;
    line-height: 3;
  }
}

h4, .h4 {
  font-size: $h4-font-size;
  margin: 0 0 30px;
}

h5, .h5 {
  font-size: $h5-font-size;
}

h6, .h6 {
  font-size: $h6-font-size;
}

p {
  margin: 0 0 1em;
}

a {
  color: $base-link-color;
  transition: all 0.3s;
  &:hover,
  &:focus {
    text-decoration: none;
    opacity: 0.8;
  }

  &[href^="tel"],
  &[href^="skype"],
  &[href^="mailto"]{
    text-decoration: none;
    font-size: 18px;  
    color: $black;

    @include media('<desktop') {
      font-size: 16px;
    }
    @include media('<phone') {
      font-size: 18px;
    }


    &:before{
      content:"\e903";
      font-family: 'icomoon';
      font-size: 1em;
      color: $orange;
      vertical-align: middle;
      display: inline-block;
      margin: 0 10px 2px 0;
    }
  }
  &[href^="skype"]{
    
    &:before{
      content:"\e907";
      color: $light-blue;
    }
  }

  &[href^="mailto"]{
    &:before{
      content: "\e902";
    }
  }

  &.btn-back{
    position: fixed;
    bottom: 10px;
    display: block;
    overflow: hidden;
    width: 40px;
    height: 45px;
    border-radius: 5px;
    z-index: 20;
    right: -50px;

    @include media('>desktop') {
      display: none;
    }

    &:before{
      content: "\e900";
      font-family: 'icomoon' !important;
      line-height: 0.8;
      position: absolute;
      top: 5px;
      left: 50%;
      transform: translateX(-55%) rotate(61deg);
      color: $white;
      font-size: 60px;
      text-shadow: 0 0 8px rgba($black,0.7);
    }

    &:hover,
    &:active{
      opacity: 1;
      

    }
  }
}