// list reset
%listreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// clearfix
%clearfix {
 &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.clearfix {
  @extend %clearfix;
}

//didden block
.hidden{
  display: none;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.pull-left,
.alignleft{
  float: left !important;
}

.alignleft{
  margin-right: 25px;

  @include media('<tablet') {
  margin-right: 15px;
    
  }
}

.pull-right,
.alignright{
  float: right !important;
}

.alignright{
  margin-left: 25px;

  @include media('<tablet') {
    
  }
}

// transition
%transition {
 transition: all 0.3s ease-in-out;
}

// justify nav
%justify {
 text-align: justify; 
 font-size: 1px;
 line-height: 0px;
 > * {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  font-size: $font-size-base;
  line-height: $line-height-base;
 }
 &:after { 
  content: '';
  width: 100%;
  display: inline-block;
  vertical-align: top;
 }
}



.ellipsis {
  white-space: nowrap; /* 1 */
  text-overflow: ellipsis; /* 2 */
  overflow: hidden;
}

