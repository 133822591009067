/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 5, 2019 */



@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/segoeuiregular-webfont.eot');
    src: url('../fonts/segoeuiregular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/segoeuiregular-webfont.woff2') format('woff2'),
         url('../fonts/segoeuiregular-webfont.woff') format('woff'),
         url('../fonts/segoeuiregular-webfont.ttf') format('truetype'),
         url('../fonts/segoeuiregular-webfont.svg#webfontregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/segoeuisemibold-webfont.eot');
    src: url('../fonts/segoeuisemibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/segoeuisemibold-webfont.woff2') format('woff2'),
         url('../fonts/segoeuisemibold-webfont.woff') format('woff'),
         url('../fonts/segoeuisemibold-webfont.ttf') format('truetype'),
         url('../fonts/segoeuisemibold-webfont.svg#webfontregular') format('svg');
    font-weight: 600;
    font-style: normal;

}


@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/segoeuilight-webfont.eot');
    src: url('../fonts/segoeuilight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/segoeuilight-webfont.woff2') format('woff2'),
         url('../fonts/segoeuilight-webfont.woff') format('woff'),
         url('../fonts/segoeuilight-webfont.ttf') format('truetype'),
         url('../fonts/segoeuilight-webfont.svg#webfontregular') format('svg');
    font-weight: 300;
    font-style: normal;

}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?hj8zlv');
  src:  url('../fonts/icomoon.eot?hj8zlv#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?hj8zlv') format('truetype'),
    url('../fonts/icomoon.woff?hj8zlv') format('woff'),
    url('../fonts/icomoon.svg?hj8zlv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-triangle-down:before {
  content: "\e900";
}
.icon-checkmark:before {
  content: "\e901";
}
.icon-mail:before {
  content: "\e902";
}
.icon-phone:before {
  content: "\e903";
}
.icon--close:before {
  content: "\e908";
}
.icon-facebook:before {
  content: "\e905";
}
.icon-vk:before {
  content: "\e906";
}
.icon-skype:before {
  content: "\e907";
}
.icon-location:before {
  content: "\e904";
}
