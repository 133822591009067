.admin-bar #header {
    top: 32px;
}

.product-list .three-columns .col {
    margin-bottom: 20px;
}

@media screen and (max-width: 782px) {
    .admin-bar #header {
        top: 46px;
    }
}

@media (max-width: 767px) {
    .product-list .three-columns .col {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 600px) {
    .admin-bar.scroll #header {
        top: 0;
    }
}

@media (max-width: 479px) {
    .admin-bar:not(.scroll) #nav {
        position: fixed;
        top: 123px;
    }
}