html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

* {
  max-height: 1000000px;

  &:focus{
    outline: none;
  }
}

body {
  color: $base-text-color;
  background: $base-background-color;
  font: #{$font-size-base}/#{$line-height-base} $base-font-family;
  min-width: $base-min-width;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// adaptive images
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

// google map fix
.gm-style img {max-width: none}

address{
  font-style: normal;
}

table{
  width: 100%;
}

section{
  padding: 30px 0;
}

.white-section{
  padding: 80px 0;

  @include media('<desktop') {
    padding: 50px 0;
  }

  @include media('<phone') {
    padding: 35px 0;
  }
}

ul{
  @extend %listreset;

  &.black-dots{
    padding: 8px 0;

    li{
      padding: 0 0 20px 60px;
      position: relative;
      &:last-child{
        padding-bottom: 0;
      }

      &:before{
        content: '';
        width: 4px;
        height: 4px;
        background: $black;
        position: absolute;
        top: 10px;
        left: 46px;
        border-radius: 55%;
      }
    }
  }

  &.orange-dots{
    padding: 8px 0;

    li{
      padding: 0 0 20px 60px;
      position: relative;
      &:last-child{
        padding-bottom: 0;
      }

      &:before{
        content: '';
        width: 4px;
        height: 4px;
        background: $orange;
        position: absolute;
        top: 10px;
        left: 46px;
        border-radius: 55%;
      }
    }
  }

  &.check-list{
    margin: 0 0 12px;
    li{
      padding: 8px 0 12px 60px;
      position: relative;

      &:before{
        content: "\e901";
        font-family: 'icomoon' !important;
        font-size: 21px;
        color: $orange;
        position: absolute;
        top: 6px;
        left: 20px;
      }
    }
  }
}
