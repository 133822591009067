// forms

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;

  &.contact-form{
    max-width: 400px;
    color: $black;


    .btn{
      border-radius: 0;
      width: 100%;
      line-height: 1.8;
    }
  }
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  box-sizing: border-box;
  border: 1px solid $form-element-border-color;
  padding: $form-element-padding;
  width: 100%;
  // max-width: 320px;
  margin: 0 0 10px;
  // height: 38px;

  // &:hover {
    
  // }

  &:focus {
    border-color: $form-element-focus-border-color;
  }

  @include placeholder {
    color: $placeholder-color;
  }
}

select {
  -webkit-border-radius: 0;
}

textarea {
  resize: vertical;
  vertical-align: top;
  // min-height: 70px;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  cursor: pointer;
  background: $orange;
  border-color: $orange;
}

.wpcf7-validation-errors,
.wpcf7-acceptance-missing,
.wpcf7-response-output,
.wpcf7-acceptance-missing,
.wpcf7-response-output,
.wpcf7-mail-sent-ok {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0.6em 1em !important;
  background: $orange !important;
  border-color: $orange !important;
  color: $white !important;
}