$breakpoints: (
  'xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px
);

// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

// ligatured operators ≥ ≤

@import 'vendors/include-media';
@import 'vendors/normalize';
@import 'vendors/fonts';
@import 'vendors/fancybox';
@import 'vendors/admin-WP-styles';

@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/reset';
@import 'base/typography';
@import 'base/forms';

body,
html{
  width: 100%;
  height: 100%;
}


body{
  display: table;

  @include media('<desktop') {
    font-size: 14px;
  }

  @include media('<phone') {
      font-size: 13px;  
  }

  &.scroll{

    .logo{
      max-width: 110px;
    }

    #header{
      box-shadow: 0 0 5px rgba($black,0.2);
      padding: 7px 0;

      .contacts{

        a{
          font-size: 16px;

          @include media('<phone') {
            font-size: 18px;
          }
        }
      }
    }
    .btn-back{
      right: 10px;
    }
  }
}

#wrapper {
  overflow: hidden;
  position: relative;
  display: table-cell;
  padding: 104px 0 0;

  @include media('<desktop') {
    padding: 77px 0 0;
  }
}

.container{
  @extend %clearfix;
  max-width: 1280px;
  padding: 0 20px;
  margin: 0 auto;

  @include media('<desktop') {
    padding: 0 15px !important;
  }

  @include media('<phone') {
    padding: 0 10px !important;
  }
}

#header{
  background: $white;
  padding: 11px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: padding 0.3s;

  @include media('<desktop') {
    padding: 7px 0;
  }

  .container{
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:after{
      display: none;
    }
  }

  .contacts{
      @include media('<phone') {
        display: none;
      }

      &.hidden{

        @include media('<phone') {
          display: block;
          padding: 0 0 30px;
        }

        li{
          padding:8px;
        }
        .btn{
          padding:8px 16px 10px;
        }
    }
  }
}

.contacts{

  li{
    margin: 0 0 5px;

    &:last-child,
    &:only-child{
      margin: 0;
    }
  }
}

.logo{
  max-width: 140px;
  transition: max-width 0.3s;

  @include media('<desktop') {
    max-width: 110px;
  }

  a{
    &:hover{
      opacity: 0.8;
    }
  }
}

#nav{
  margin: 0 0 0 4%;
  
  @include media('<desktop') {
    position: absolute;
    right: -100%;
    top: calc(100% - 1px);
    width: 280px;
    box-shadow: 0 3px 3px rgba($black,0.2);
    background: $white;
    text-align: center;
    transition: right 0.3s;
    margin: 0;
  }

  @include media('<phone') {
    position: fixed;
    top: 75px;
    width: 100%;
    bottom: 0;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 50;
    box-shadow: 0 3px 3px rgba($black,0.4);
  }

}

.menu{
  display: flex;

  @include media('<desktop') {
    display: block;
  }

  li{
    padding: 0 18px 5px;
    position: relative;

    @include media('<desktop') {
      padding: 0;
    }

    &:hover,
    &:active{
      
      span{
        color: $black;
        &:before{
          display: none;
        }
      }
    }

    &.active{
      > a,
        span{
        color: $orange;

        @include media('<desktop') {
          color: $white;
          border-color: $white;
          background: $orange;
        }

        &:before{
          right: 0;
        }
      }
    }

    &.dropdown{

      &:hover,
      &:active{

        ul{
          top: 100% !important;
          opacity: 1 !important;

          @include media('<desktop') {
            display: block !important;
          }
        }
        .icon-triangle-down{
          opacity: 0;

          @include media('<desktop') {
            opacity: 1;
            color: $black;
          }
        }
      }

      &.active{

        ul{
           @include media('<desktop') {
            display: block !important;
          }
        }
      }

      .icon-triangle-down{
        content: "\e900";
        font-family: 'icomoon' !important;
        font-size: 23px;
        position: absolute;
        left: -21px;
        top: 40%;
        transform: translateY(-50%);
        color: $orange;

        @include media('<desktop') {
          position: static;
          display: inline-block;
          vertical-align: middle;
          margin: 0 0 4px;
          transform: translateY(0);

        }
      }

      ul{
        background: $white;
        position: absolute;
        padding: 25px 0 10px;
        top: -200px;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        opacity: 0;
        box-shadow: 0 6px 8px rgba($black,0.25);
        transition: opacity 0.3s;

        @include media('<desktop') {
          position: static;
          top: 0;
          left: 0;
          width: auto;
          opacity: 1;
          transform: translateX(0);
          box-shadow: none;
          padding: 0;
          display: none;
        }

        li{
          padding-bottom: 15px;
          text-align: center;

          @include media('<desktop') {
            padding-bottom: 0;
          }
        }

        a{
          font-size: 17px;
          &:before{
            display: none;
          }

          @include media('<desktop') {
            font-size: 13px;
          }
        }
      }
    }

  }

  a,
  span{
    color: $black;
    font-size: 20px;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
    display: block;

    @include media('<desktop') {
      font-size: 16px;
      padding: 10px 15px;
      border:  solid transparent;
      border-width: 1px 0;
    }

    @include media('<phone') {
      font-size: 20px;
    }

    &:hover,
    &:active{
      color: $orange;
      opacity: 1;

      @include media('<desktop') {
        color: $white;
        background: $orange;
        border-color: $orange;
      }

      &:before{
        right: 0;
      }
    }

    &:before{
      content:"";
      position: absolute;
      left: 0;
      right: 100%;
      bottom: -5px;
      height: 2px;
      background: $orange;
      transition: all 0.3s;

      @include media('<desktop') {
        display: none;
      }
    }
  }
}

.nav-opener{
  width:40px;
  height:40px;
  position:relative;
  border-radius:3px;
  text-indent:-9999px;
  overflow:hidden;
  margin: 6px 0 4px 10px;
  order: 2;

  @include media ('>desktop') {display: none;}
    &:before,
    &:after,
    & span{
      background:$black;
      border-radius:4px;
      position:absolute;
      top:16px;
      left:10%;
      right:10%;
      height:3px;
      margin-top:-2px;
      transition:all 0.2s linear;
    }
    &:before,
    &:after{
      content:'';
      top:9px;
    }
    &:after{top:23px;}
    &:hover{opacity:.9;}
}

.nav-active {

  #nav {
   right: 0;
  }

  .menu{
    li{}
  }

  .nav-opener {

    span{
      opacity:0;
    }

    &:after,
    &:before{
      transform:rotate(45deg);
      top:16px !important;
      left:15%;
      right:15%;
    }

    &:after{
      transform:rotate(-45deg);
    }
  }
}

.btn{
  display: inline-block;
  vertical-align: top;
  padding:4px 16px 7px;
  text-align: center;
  text-decoration: none;
  color: $white;
  background: $orange;
  border-radius: 15px;
  font-size: 16px;
  letter-spacing: normal;
  overflow: hidden;
  position: relative;
  border: 2px solid $orange;

  @include media('<phone') {
    font-size: 15px;
    line-height: 1.8;
    padding:4px 12px 7px;

  }

  &:before{
    content:"";
    position: absolute;
    right: 110%;
    width: 60px;
    bottom: 0;
    top: 0;
    background: rgba($white,0.4);
    transition: all 0.45s ease-in-out;
    transform: skewX(35deg);
  }

  &:hover,
  &:active{
    opacity: 1;

    &:before{
      right: -70px;
      
    }
  }
}

.visual-section{
  background-size: cover;
  background-position: 50% 0;
  padding: 0;

  @include media('<tablet') {
    background-image: url(../img/bg-01-small.jpg) !important;
  }

  .container{
    padding: 0 !important;
  }

  .slogan-holder{
    letter-spacing: -0.05em;
    padding: 30px 60px 40px;
    background: rgba($white,0.54);
    max-width: 650px;
    text-transform: uppercase;
    font: 24px/1.63 $base-font-sans-serif;

    @include media('<desktop') {
      font-size: 20px;
      padding: 35px ;
    }

    @include media('<tablet') {
      font-size: 17px;
    }

    @include media('<phone') {
      background: rgba($white,0.7);
      font-size: 16px;
      letter-spacing: 0.03em;
      padding:30px 15px 35px;
    }

    p{
      margin: 0 0 1.7em;
    }

    .btn{
      text-transform: none;

      @include media('<phone') {
        width: 100%;
        max-width: 302px;
      }
    }
  }
}

.product-list{

  .three-columns{

    .col{
      padding: 0 10px;
      text-align: center; 
      text-transform: uppercase;
      font-size: 18px; 
      line-height: 2.2;
      display: flex;

      @include media('<desktop') {
        line-height: 1.8;
        font-size: 13px;
      }

      @include media('<tablet') {
        padding: 10px;
      }

      @include media('<phone') {
        display: block;
      }
    }

    .border-holder{
      display: flex;
      border: 1px solid $border-color-1;
      padding: 10px 10px 15px;
      text-decoration: none;
      flex-direction: column;
      justify-content: space-between;

      &:hover{
        opacity: 1;

        .img-holder{
          img{
            transform: scale(1.06);
          }
        }

      }

      .img-holder{
        overflow: hidden;
        margin: 0 0 15px;

        img{
          width: 100%;
          transition: transform 0.3s;
        }
      }

      .title{
        font-weight: normal; 
        color: $black; 
      }
    }
  }
}

.description-section{
  background-size: cover;
  background-position: 50% 0;
  padding: 40px 0;
  text-align: center;

  @include media('<tablet') {
    background-image: url(../img/bg-02-small.jpg) !important;
  }

  .container{
    max-width: 1060px;
  }

  .greeting{
    display: block;
    font-size: 24px;
    margin: 0 0 20px;
    font-weight: normal;

    @include media('<phone') {
      font-size: 20px;
    }
  }
}

.characteristic{
  padding: 50px 0;
  color: $white;
  background-size: cover;
  background-position: 50% 0;

  @include media('<phone') {
    padding-bottom: 30px;
  }

  .text-holder{
    font-size: 24px;
    margin: 0 auto 20px;
    max-width: 1060px;

    @include media('<desktop') {
      font-size: 20px;
    }
    @include media('<tablet') {
      font-size: 18px;
    }
    @include media('<phone') {
      font-size: 17px;
    }
  }

  .three-columns{
    justify-content: space-between;

    @include media('<phone') {
      justify-content: center;
    }

    .col{
      font-size: 18px;
      font-weight: 300;
      width: 25.1%;
      margin: 0 0 15px;

      @include media('<desktop') {
        width: 33.33%;
        font-size: 17px;
      }
      @include media('<tablet') {
        width: 50%;
        font-size: 15px;
      }
      @include media('<phone') {
        width: 75%;
      }

      .img-box{
        margin: 0 auto 15px;
        width: 100px;
        height: 100px;
        padding: 10px;
        border: 2px solid $orange;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.option-list{
  padding: 25px 0 0;

  @include media('<phone') {
    padding: 10px 0 0;
  }

  .open-holder{
    margin: 0 0 -5px;

    @include media('<tablet') {
      margin-bottom: -3px;
    }

    &:last-child{
      margin: 0;
    }

    &.active{
      margin: 0 0 20px;

      .slide{
        max-height: 999px;
        opacity: 1;
        margin: -30px 0 0;
      }

      .opener{

        &:before{
          transform:translateY(-49%) rotate(61deg);
        }
      }
    }
  }

  .opener{
    display: block;
    padding:0.5em 58px 0.54em 22px;
    margin: 0;
    border: 1px solid $orange;
    border-radius: 15px;
    background: $white;
    position: relative;
    z-index: 10;

    @include media('<phone') {
      padding-right: 35px;
      padding-left: 15px;
    }

    &:hover,
    &:active{
      cursor: pointer;
    }

    &:before{
      content: "\e900";
      font-family: 'icomoon' !important;
      font-size: 32px;
      line-height: 0.8;
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-49%);
      color: $orange;
      transition: all 0.3s;

      @include media('<tablet') {
        font-size: 25px;
        right: 10px;
      }
    }
  }

  .slide{
    transition: all 0.35s;
    box-shadow: 4px 2px 20px rgba($black,0.12);
    font-size: 16px;
    overflow: hidden;
    max-height: 0;
    opacity: 0;

    @include media('<tablet') {
      font-size: 13px;
      line-height: 1.5;
    }
    @include media('<phone') {
      font-size: 13px;
      box-shadow: 0 2px 20px rgba($black,0.12);

    }

    .anim-holder{
      padding:40px 25px 30px;

      @include media('<phone') {
        padding: 4s0px 15px 10px;
      }
    }

    .black-dots{
      margin: 0 0 18px;

      li{
        padding-left: 40px;

        &:before{
          left: 25px;
        }
      }
    }
  }

  .four-columns{
    justify-content: space-around;
    padding: 15px 0 10px;
    font-size: 18px;

    @include media('<tablet') {
      font-size: 14px;
    }

    .col{
      max-width: 185px;
      text-align: center;

      @include media('<tablet') {
        max-width: none;
      }
    }

    .img-box{
      margin: 0 auto 15px;
      width: 100px;
      height: 100px;
      padding: 10px;
      border: 2px solid $orange;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media('<tablet') {
        margin-bottom: 10px;
      }
    }
  }
}

.two-columns{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;

  .col{
    width: 50%;
    padding: 10px;

    @include media('<phone') {
      width: 100%;
    }
  }
}

.three-columns{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px;

  .col{
    width: 33.33%;
    padding: 10px;

    @include media('<tablet') {
      width: 50%;
    }

    @include media('<phone') {
      width: 100%;
    }
  }
}

.four-columns{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px;

  .col{
    width: 25%;
    padding: 10px;

    @include media('<tablet') {
      width: 50%;
    }
  }
}

.info-block{
  background: $orange;
  font-size: 22px;
  line-height: 1.8;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  padding: 30px 0;

  @include media('<widescreen') {
    font-size: 19px;
  }

  @include media('<desktop') {
    font-size: 16px;
  }

  @include media('<tablet') {
    font-size: 13px;
    padding: 10px 0;
  }

  p{
    margin: 0;
  }
}

.contact-section{
  padding: 40px 0;

  @include media('<desktop') {
    padding: 40px 0;
  }

  .container{
    max-width: 900px !important;
  }

  .two-columns{

    .col{
      @include media('<600px') {
        width: 100%;
      }
    }
  }

  .title{
    font-size: 24px;
    text-transform: uppercase;
    display: block;
    margin: 0 0 30px;
  }
  color: $white;

  .contacts{
    li{
      margin: 0 0 12px;
    }
    a{
      color: $white;
    }

    .map{
      @include media('<phone') {
        font-size: 18px;
      }
    }
  }
}

.maps-section{
  text-align: center;
}

.post-section{

  .three-columns{

    .col{
      @include media('<tablet') {
        width: 100%;
      }
    }
  }

  .post{
      font-size: 14px;
      color: $black;
      box-shadow: 0 4px 13px rgba($black,0.3);
      transition: all 0.3s;

      @include media('<desktop') {
        font-size: 13px;
      }
     
      &:hover,
      &:active{
        box-shadow: 0 0 13px rgba($black,0.50);
      }

    .bg-holder{
      height: 200px;
      background-size: cover;
      background-position: 50% 0;
      overflow: hidden;
    }

    .text-holder{
      padding: 15px;
    }

    time{
      display: block;
      margin: 0 0 15px;
      color: lighten($black,35%);
    }

    .title{
      text-transform: none;
      color: $black;
      border: none;
      font-weight: bold;
      margin-bottom: 15px;

      @include media('<desktop') {
        font-size: 14px;
      }
    }
  }
}

.download-price{
  display: flex;
  align-items: center;
  font-size: 16px;

  time{
    margin:0 0 4px 20px;
  }
  .btn{

    i{
      display: inline-block;
      vertical-align: middle;
      max-width: 18px;
      margin-left: 10px;
    }
  }
}

.sub-menu{
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 3;
  text-transform: uppercase;
  font-size: 16px;

  @include media('<phone') {
    font-size: 14px;
  }

  &:before{
    content:"";
    position: absolute;
    bottom: 0;
    top: 0;
    left: -20vw;
    right: -20vw;
    background: $bg-footer;
    z-index: -1;
  }

  li{
    padding: 15px 25px;

    @include media('<phone') {
      padding: 12px;
    }
  }

  a{
    color: $white;
    text-decoration: none;

    &:hover,
    &:active{
      color: $orange;
      opacity: 1;
    }
  }
}

.product-description{
  
  .title-holder{
    margin: 0 0 20px;
  }

  .product-foto{
    max-width: 400px;

    @include media('<desktop') {
      max-width: 40%;
    }
    @include media('<tablet') {
      max-width: 320px;
    }

    @include media('<tablet') {
      float: none !important;
      margin: 0 auto 20px;
    }

    &.product-foto-bigger{
      max-width: none;

      img{
        width: 100%;
      }
    }
  }
  .description-text{
    line-height: 1.5;
    overflow: hidden;
  }
}

#footer {
  display: table-footer-group;
  height: 1%;
  background: $bg-footer;
  color: $white;

  .container{
    padding: 15px 20px 5px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @include media('<desktop') {
      padding: 10px 15px !important;
    }

    @include media('<phone') {
      flex-direction: column;
      padding: 10px !important;
    }

    &:after{
      display: none;
    }
  }

  .footer-logo{
    max-width: 278px;

    @include media('<widescreen') {
      max-width: 170px;
    }

    @include media('<desktop') {
      order: 3;
    }

    @include media('<phone') {
      padding: 10px 0 20px;
    }
  }

  .footer-menu{
    display: flex;
    flex-wrap: wrap;
    
    @include media('<desktop') {
      display: block;
      order: 2;
    }

    @include media('<tablet') {
      display: flex;
      justify-content: center;
      width: 100%;
      order: 1;
      padding: 20px 0;
    }

    li{
      padding: 0 16px;

      @include media('<widescreen') {
        padding: 0 10px;
      }
      @include media('<desktop') {
        padding: 5px 10px;
        text-align: center;
      }

      @include media('<tablet') {
        padding: 5px 8px;
      }

      &.active a{
        color: $orange;

        &:before{
          right: 0;
          
        }
      }
    }
    
    a{
      color: $white;
      text-decoration: none;
      font-size: 22px;
      position: relative;

      @include media('<widescreen') {
        font-size: 19px;
      }
      @include media('<tablet') {
        font-size: 17px;
      }

      &:hover,
      &:active{
        color: $orange;
        opacity: 1;

        &:before{
          right: 0;
        }
      }

      &:before{
        content:"";
        position: absolute;
        left: 0;
        right: 100%;
        bottom: -5px;
        height: 2px;
        background: $orange;
        transition: all 0.3s;

        @include media('<desktop') {
          display: none;
        }
      }
    }
  }

  .contacts{
    padding: 5px 0;

    @include media('<tablet') {
      order: 2;
    }
    @include media('<phone') {
      order: 3;
      text-align: center;
      margin: 0 0 8px;
    }

    a{
      color: $white;
      text-decoration: none;
      font-size: 15px;

      &[href^="tel"],
      &[href^="skype"],
      &[href^="mailto"],
      &.map{

        &:before{
          color: $white;
          transition: all 0.3s;
        }

        &:hover,
        &:active{
          opacity: 1;
        }
      }

      &[href^="tel"],
      &.map{
        &:hover,
        &:active{
          
          &:before{
            color: $orange;
          }
        }
      }

      &[href^="skype"]{
        &:hover,
        &:active{
          
          &:before{
            color: $light-blue;
          }
        }
      }
      &[href^="mailto"]{
        &:before{
          font-size: 18px;
        }
      }
    }
  }


  .copyright{
    border-top: 1px solid $orange;
    padding: 10px 0;
    margin: 0;
    text-align: center;
    font-size: 22px;

    @include media('<widescreen') {
      font-size: 18px;
    }

    @include media('<desktop') {
      font-size: 15px;
    }
  }
}

.map{
  &:before{
    content: "\e904";
    font-family: 'icomoon';
    font-size: 1em;
    color: $orange;
    vertical-align: middle;
    display: inline-block;
    margin: 0 10px 2px 0;
  }
}

.ui-dialog{
  z-index: 5;

  &:before{
    content:"";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $black;
    opacity: 0.5;
    z-index: 5;
  }

  .dialog{
    background: $white;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 10px rgba($black,0.8);
    z-index: 14;
    position: relative;

  }

  .ui-dialog-titlebar-close{
    position: absolute;
    top: 10px;
    right: -2px;
    font-size: 0;
    width: 22px;
    height: 22px;
    padding: 0;
    border: none;
    background: $white;
    border-radius: 50%;
    z-index: 15;

    &:before{
      content: "\e908";
      font-family: 'icomoon' !important;
      font-size: 20px;
      line-height: 0.9;
    }
  }
}

table.excel{
  font-size: 15px;
  border-collapse: collapse;
  letter-spacing: 0.07em;
  box-shadow: 0 0 15px rgba($black,0.18);
  margin: 0 0 50px;

  tr{

    &:first-child,
    &:nth-child(2),
    &:nth-child(3){
       background: rgba(255,112,7,0.8) !important;

       td{
        font-weight: bold;
        padding: 1em;
        font-size: 12px;
        color: $white;
        text-shadow: 1px 1px 2px rgba($black,0.9);
       }
    }

    &:nth-child(odd){
      background: lighten(#cecece,15%);
    }

    td{
      border: 1px solid rgba($black,0.4);
      padding: 0.4em 0.4em 0.4em 0.7em;
    }
  }

  a.lightbox{
    max-width: 300px;
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
  }
}

.breadcrambs{
  @extend %clearfix;
  padding: 20px 0 10px;

  li{
    font-size: 15px;
    float: left;
    padding-right: 30px;
    position: relative;

    &:before{
      content: "\e900";
      font-family: 'icomoon' !important;
      transform: rotate(28deg);
      position: absolute;
      right: 13px;
      font-size: 15px;
      top: 2px;
    }
    &:last-child{
      &:before{
        content:"";
        
      }
    }
  }

  a{
    text-decoration: none;

    &:hover,
    &:active{
      text-decoration: underline;
    }
  }
}
